import React from "react";
import "./src/styles/global.css";
import {LocationInfoProvider} from "./src/context/LocationInfoContext";
import ReactDOM from "react-dom/client";
import {UTMProvider} from "./src/context/utmContext";
import {setUTMParameters} from "./src/utils/utmSetter";

export const wrapRootElement = ({ element }) => (
    <LocationInfoProvider>
        <UTMProvider>
        {element}
        </UTMProvider>
    </LocationInfoProvider>
);

export const replaceHydrateFunction = () => {
    return (element, container) => {
        const root = ReactDOM.createRoot(container);
        root.render(element);
    };
};

export const onRouteUpdate = () => {
    setUTMParameters();
};
